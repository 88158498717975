import axios from 'axios';
import { Abstract } from './Abstract.js';

export class InventoryMovement extends Abstract
{
    constructor(session)
    {
        super('Inventory/InventoryMovement', session);
        this.name = null;
        this.key_name = null;
        this.id_movement_type = null;
    }

    async getCustom(){
        let response = await axios.get(this.controller + "/getCustom", {
            headers: {
                usuario: this.usuario,
                empresa: this.intEmpresa,
                sucursal: this.intSucursal
            }
        });
        return response.data;
    }
}