<template>
    <div class="grid crud-demo">
        <Loader v-model = "loading"/>
        <Helper v-model = "InventoryMovementHelper" 
            header = "Buscar InventoryMovement" 
            :headers = "headers" 
            :rows = "entities" 
            @selected = "selectEntity" 
        />
        <div class="col-12">
            <div class="card">
                <Toast />
                <h2>Movimientos</h2>
                <BasicFormToolbar 
                    @new = "openNew"
                    @save = "save"
                    @refresh = "refresh"
                    @search = "InventoryMovementHelper.visible = true"
                    @deleted = "confirmDelete"
                    :actions = "['new', 'refresh', 'delete', 'save', 'search']"
                />
                <Fieldset :tooggleable = "true" legend = "Informacion">
                    <div class="formgrid grid p-fluid">
                        <FormInputText 
                            wrapperClass = "'field col-12 md:col-4 xl:col-4'"
                            label = "Nombre"
                            v-model = "entity.name"
                            :valid = "validate.validations.name"
                        />

                        <FormInputText 
                            wrapperClass = "'field col-12 md:col-4 xl:col-4'"
                            label = "Descripcion"
                            v-model = "entity.key_name"
                            :valid = "validate.validations.key_name"
                        />

                        <FormDropdownComplex 
                            :labelInOption = "'id - name'"
                            :labelInValue = "'id - name'"
                            :filterFields = "['id', 'name']"
                            :wrapperClass = "'field col-12 md:col-4 xl:col-4'"
                            label = "Tipo de Movimiento"
                            v-model = "entity.id_movement_type"
                            :options = "id_movement_type"
                            optionLabel = "name"
                            optionValue = "id"
                        />
                    </div>
                </fieldset>
                
                <BasicDatatable
                    :rowedit = "true"
                    :rowaction = "true"
                    :rowdelete = "true"
                    :headers = "headers"
                    :rows = "entities"
                    @edited = "edit"
                    @deleted = "confirmDelete"
                />

                <ConfirmDeleteDialog 
                    @closed = "deleteDialog = false"
                    v-model = "deleteDialog"
                    @deleted = "deleteEntity"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from '../../../components/general/Loader.vue';
    import Session from '../../../mixins/sessionMixin';
    import Helper from '../../../components/general/HelperDialog.vue';
    import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
    import BasicDatatable from '../../../components/general/BasicDatatable.vue';
    import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
    import { ErrorToast, HeaderGrid, Rule, fillObject, validate, Toast } from '../../../utilities/General';
    import { FilterMatchMode } from 'primevue/api';

    import FormInputText from '../../../components/general/FormInputText.vue';
    import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";

    import { Movement_Type } from '../../../models/inventarios/MovementType';
    import { InventoryMovement } from '../../../models/inventarios/InventoryMovement.js';


    export default {
        mixins: [Session],
        data(){
            return {
                entity: null,
                entities : [],
                id_movement_type: [],
                /*URL para subir el importador*/
                uploadURL: null,
                newDialog: null,
                deleteDialog: false,
                InventoryMovementHelper: {
                    visible: false,
                },
                filters: {},
                /*Reglas de validación*/
                rules: [
                    new Rule({name: 'name'}),
                    new Rule({name: 'key_name'}),
                    new Rule({name: 'id_movement_type'})
                ],
                validate: {
                    valid: false,
                    validations: {
                        name: null,
                        key_name: null,
                        id_movement_type: null
                    }
                },
                headers: [
                    new HeaderGrid('Nombre', 'name'),
                    new HeaderGrid('Nombre Corto', 'key_name'),
                    new HeaderGrid('Tipo Movimiento', 'strname')
                ],
                loading: false
            }
        },
        components:{
            Loader,
            Helper,
            BasicFormToolbar,
            FormInputText,
            FormDropdownComplex,
            BasicDatatable,
            ConfirmDeleteDialog
        },
        created() {
            console.log(this.session);
            this.entity = new InventoryMovement(this.session);
            this.uploadURL = this.$config.api_route + "Inventory/InventoryMovement/Import/" + this.session.empresa + "/" + this.session.usuario;
            this.initFilters();
        },
        async mounted() {
            try{
                this.id_movement_type = await new Movement_Type(this.session).getCustom();
                await this.refresh();
            } catch(ex){
                console.log("Error en mounted: ", ex);
            }
        },
        methods: {
            openNew() {
                this.entity = new InventoryMovement(this.session);
                this.newDialog = true;
            },
            hideDialog() {
                this.newDialog = false;
            },
            async save() {
                try {
                    console.log("Entra: ", this.entity);
                    /*Iniciamos loader*/
                    this.loading = true;
                    /*Validamos que no falte ningun dato*/
                    this.validate = validate(this.entity, this.rules);
                    if(!this.validate.valid) throw 'Favor de validar los campos';
                    /* id == 0 ? Guardamos : Actualizamos */
                    if(this.entity.id && this.entity.id > 0) {
                        console.log("Actualizamos");
                        /*Actualizamos*/    
                        await this.entity.update();
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Actualizar',
                            detail: 'Información actualizada con éxito',
                            life: 3000
                        });
                        /*Modificamos Tabla Inferior*/
                        //let index = this.entities.findIndex((x) => x.id == this.entity.id);
                        //this.entities[index] = entity;
                        this.refresh();
                    } else {
                        console.log("Guardamos");
                        /*Guardamos*/
                        await this.entity.save();
                        this.$toast.add(
                            new Toast({
                                summary: 'Creación',
                                detail: 'Información guardada con éxito'
                            })
                        );
                        /*Modificamos Tabla Inferior*/
                        //this.entities.push(entity);
                        this.refresh();
                    }
                    this.entity = new InventoryMovement(this.session);
                    this.newDialog = false;
                } catch (ex) {
                    /* Se arroja toast con error y se imprime en consola */
                    this.$toast.add(new ErrorToast(ex));
                } finally {
                    /* Finalizamos loader */
                    this.loading = false;
                }
            },
            selectEntity(InventoryMovement) {
                this.entity = fillObject(this.entity, InventoryMovement);
            },
            edit(entity) {
                this.entity = fillObject(this.entity, entity);
                this.newDialog = true;
            },
            confirmDelete(entity) {
                this.entity = fillObject(this.entity, entity);
                this.deleteDialog = true;
            },
            async deleteEntity() {
                try {
                    /* Iniciamos Loader */
                    this.loading = true;
                    console.log("entity: ", this.entity);
                    /* Eliminamos de la base de datos */
                    await this.entity.delete();
                    /* Eliminamos de la tabla inferior */
                    this.entities = this.entities.filter(val => val.id !== this.entity.id);
                    this.deleteDialog = false;
                    /* Limpiamos la entidad */
                    this.entity = new InventoryMovement(this.session);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Eliminacion',
                        detail: 'Registro eliminado con exito',
                        life: 3000
                    });
                } catch (ex) {
                    /* Se arroja toast con error y se imprime en consola */
                    this.$toast.add(new ErrorToast(ex));
                } finally {
                    /* Finalizamos loader */
                    this.loading = false;
                }
            },
            initFilters() {
                this.filters = {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
                };
            },
            uploadError() {
                this.$toast.add(new ErrorToast('Error al intentar importar archivo'));
            },
            async refresh() {
                /* Iniciamos loader */
                this.loading = true;
                try {
                    this.entities = await this.entity.getCustom();
                    console.log(this.entities);
                } catch (ex) {
                    this.$toast.add(new ErrorToast(ex));
                } finally {
                    /* Finalizamos loader */
                    this.loading = false;
                }
            }
        }
    };
</script>

<style scoped lang="scss">
</style>