import axios from 'axios';
import { Abstract } from './Abstract';

export class Movement_Type extends Abstract {
    constructor(session) 
    {
        super("inventory/MovementType",  session);
        this.name = null;
        this.key_name = null;
        //this.type = null;
        this.id_document_type = null;
    }

    async getCustom() {
        let response = await axios.get(this.controller + "/getCustom", {
            headers: {
                usuario: this.usuario,
                empresa: this.intEmpresa,
                sucursal: this.intSucursal
            }
        });
        return response.data;
    }

    async data() {
        let response = await axios.get(this.controller + "/data", {
            headers: {
                usuario: this.usuario,
                empresa: this.intEmpresa,
                sucursal: this.intSucursal
            }
        });
        return response.data;
      }
}



